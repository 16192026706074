import React, { FunctionComponent } from "react"
import { Layout } from "../components/layout/Layout"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import { useStageImages } from "../utils/stageImages"

const stageStyles = css({
  width: 180
})

const IndexPage: FunctionComponent = () => {
  const {
    stageBattlefield,
    stageFD,
    stageSmashville,
    stagePS,
    stageTown,
    stageLylat,
    stageKalos,
    stageYoshis,
  } = useStageImages()

  return (
    <Layout>
      <h1>Rules</h1>

      <h4>Game 1</h4>
      <ul>
        <li><strong>First Ban Selection</strong></li>
        <ul>
          <li>Select a player which will get to ban the first stage:</li>
          <ul>
            <li>Virtual rock paper scissors</li>
            <li><Link  to="/coin-flip">Coin Flip</Link></li>
          </ul>
          <li>
          Alternatively, you can choose to "Gentleman" to a starter stage
            <ul>
              <li>If both players agree to start on a specific stage, that is fine</li>
              <li>(Typically, this is Pokemon Stadium 2 [PS2])</li>
            </ul>
          </li>
        </ul>
        <li><strong>Stage Selection</strong></li>
        <ul>
          <li>For Game 1, stages can only be selected from the list of starter stages.</li>
          <li>Stage bans are done in the following order:</li>
          <ul>
            <li>Player 1</li>
            <li>Player 2</li>
            <li>Player 2</li>
            <li>Player 1</li>
          </ul>
          <li>After banning is complete, the last remaining starter stage is selected for game 1.</li>
        </ul>
        <li><strong>Character Selection</strong></li>
        <ul>
          <li>After selecting a stage, each player should choose their character "double blind" (at the same time)</li>
          <li>If anyone feels like the selection process was unfair, contact a TO.</li>
        </ul>
      </ul>
      <br/>

      <h4>Game 2+</h4>
      <ul>
        <li><strong>Stage Selection</strong></li>
        <ul>
          <li>For game 2+, the counterpick stages become available stage choices.</li>
          <li>The winner of the previous game bans 2 stages.</li>
          <li>The loser of the previous game can pick any of the 6 remaining stages.</li>
        </ul>
        <li><strong>Character Selection</strong></li>
        <ul>
          <li>The winner of the previous game must select their character first.</li>
          <li>The loser of the previous game selects their character only after the other player has selected their character.</li>
        </ul>
        <li><strong>Repeat this process until a player has won the set.</strong></li>
      </ul>
      <br/>

      <h4>How many games do we play?</h4>
      <ul>
        <li><strong>Winners Finals, Losers Finals, Grand Finals</strong></li>
        <ul>
          <li>Best of 5</li>
        </ul>
        <li><strong>Everything Else</strong></li>
        <ul>
          <li>Best of 3</li>
        </ul>
      </ul>

      <br/>
      <br/>

      <h3>Starter Stages</h3>

      <table>
        <tbody>
          <tr>
            <td>
              <Img fixed={stageBattlefield} css={stageStyles} />
            </td>
            <td>
              <Img fixed={stageFD} css={stageStyles} />
            </td>
            <td>
              <Img fixed={stageSmashville} css={stageStyles} />
            </td>
            <td>
              <Img fixed={stagePS} css={stageStyles} />
            </td>
            <td>
            <Img fixed={stageTown} css={stageStyles} />
            </td>
          </tr>
          <tr>
            <td>
              Battlefield
            </td>
            <td>
              Final Destination
            </td>
            <td>
              Smashville
            </td>
            <td>
              Pokemon Stadium 2
            </td>
            <td>
              Town & City
            </td>
          </tr>
        </tbody>
      </table>
      <br/>
      
      <h3>Counterpick Stages</h3>

      <table>
        <tbody>
          <tr>
            <td>
              todo...
            </td>
            <td>
              <Img fixed={stageKalos} css={stageStyles} />
            </td>
            <td>
              <Img fixed={stageYoshis} css={stageStyles} />
            </td>
          </tr>
          <tr>
            <td>
              Small Battlefield
            </td>
            <td>
              Kalos Pokemon League
            </td>
            <td>
              Yoshi's Story
            </td>
          </tr>
        </tbody>
      </table>

      <br/>
      <br/>
      <br/>
      <hr/>
      <br/>

      <h1>Full Ruleset</h1>

      We are following the same ruleset as the Smash World Tour.
      [<a href="https://smashworldtour.com/wp-content/uploads/2020/03/SWT-2020-Rulebook.pdf" target="_blank">Smash World Tour Rulebook</a>]
      <br/>
      <br/>

      <h4>Game Settings</h4>
      <ul>
        <li>Stock: 3</li>
        <li>Timer: 7:00</li>
        <li>Handicap: Off</li>
        <li>Team Attack: On</li>
        <li>Launch Rate: 1.0x</li>
        <li>Items: Off and None</li>
        <li>FS Meter: Off</li>
        <li>Damage Handicap: Off</li>
        <li>Custom Balance: Off</li>
        <li>Spirits: Off</li>
        <li>Pause: Off</li>
        <li>Stage Selection: Loser’s Pick</li>
        <li>Stage Hazards: Off</li>
        <li>Stage Morph: Off</li>
        <li>Underdog Boost: Off</li>
        <li>Score Display: Off</li>
        <li>Show Damage: Yes</li>
      </ul>
      <br/>

      <h4>Options</h4>
      <ul>
        <li>Radar: Small</li>
        <li>Echo Fighters: Separate</li>
        <li>Custom Balance: Off</li>
        <li>Mii Fighters are permitted with any combination of specials.</li>
        <li>Language: English</li>
      </ul>
      <br/>

    </Layout>
  )
}

export default IndexPage
