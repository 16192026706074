

import { useStaticQuery, graphql } from "gatsby"

export const useStageImages = () => {
  const stageFiles = useStaticQuery(graphql`
    query stageFiles {
      stageBattlefield: file(relativePath: { eq: "images/battlefield.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      stageFD: file(relativePath: { eq: "images/final-destination.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      stageSmashville: file(relativePath: { eq: "images/smashville.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      stagePS: file(relativePath: { eq: "images/ps2.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      stageTown: file(relativePath: { eq: "images/town-and-city.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      stageLylat: file(relativePath: { eq: "images/lylat.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      
      stageKalos: file(relativePath: { eq: "images/kalos.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      stageYoshis: file(relativePath: { eq: "images/yoshis-story.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const stageBattlefield = stageFiles['stageBattlefield']['childImageSharp']['fixed']
  const stageFD = stageFiles['stageFD']['childImageSharp']['fixed']
  const stageSmashville = stageFiles['stageSmashville']['childImageSharp']['fixed']
  const stagePS = stageFiles['stagePS']['childImageSharp']['fixed']
  const stageTown = stageFiles['stageTown']['childImageSharp']['fixed']
  const stageLylat = stageFiles['stageLylat']['childImageSharp']['fixed']
  const stageKalos = stageFiles['stageKalos']['childImageSharp']['fixed']
  const stageYoshis = stageFiles['stageYoshis']['childImageSharp']['fixed']

  return {
    stageBattlefield,
    stageFD,
    stageSmashville,
    stagePS,
    stageTown,
    stageLylat,
    stageKalos,
    stageYoshis,
  }
}